import { defineStore } from 'pinia'
import { pinia } from '@/pinia'
import _ from 'lodash'
import { LocaleType } from '@/interface/common'
import config from '@/config'
import i18n from '@/locale'
interface SettingState {
    locale: LocaleType
}

export const useSettingStore = defineStore({
    id: 'setting',
    state: (): SettingState => ({
        locale: localStorage.getItem('localepc') as LocaleType ?? config.locale
    }),
    getters: {
        getLocale(): LocaleType {
            return this.locale
        }
    },
    actions: {
        setLocale(type: LocaleType) {
            this.locale = type
            localStorage.setItem('localepc', this.locale)
            i18n.locale = this.locale
            location.reload()
        }
    }
})

// Need to be used outside the setup
export function useSettingStoreWithOut() {
    return useSettingStore(pinia)
}
